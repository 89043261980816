<template>
  <div v-if="data">
    <v-breadcrumbs :items="data.breadcrumbs"></v-breadcrumbs>
  </div>
</template>

<script>
import Base from './Base.vue';
export default {
  extends: Base,
  data() {
    return {};
  }
};
</script>

<style lang="scss">
</style>
